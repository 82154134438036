<template>
    <div :class="playModal ? 'tw-event-location-container' : ''" class="pb-5">
        <div class="row justify-content-center py-5 rounded-3">
            <div class="col-md-8 col-sm-12">
                <div class="card card-rounded-3">
                    <div class="card-header tw-red-bg-color text-white rounded-3">
                        <div class="row rounded-3 pt-3">
                            <p class="fs-5">Set event location</p>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row p-3">
                            <label for="event-location" class="form-label"></label>
                            <input id="event-location" type="text" ref="search" class="form-control rounded-pill" placeholder="Start typing address..." />
                        </div>
                        <div class="row"><div id="map" style="width: 100%; height: 500px"></div></div>
                        <div class="row p-3">
                            <div class="col-12 my-3">
                                <button class="tw-red-bg-color text-white py-2 px-3 rounded-pill border-0" @click.prevent="saveLocation">
                                    <i class="fas fa-plus"></i>
                                    <span class="fs-5 ps-2">Save Location</span>
                                </button>
                                <router-link to="/events" class="btn tw-dark-grey-bg-color text-white rounded-pill float-end fs-5 cursor-pointer" type="button">Cancel</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import heyGovMixin from "@/mixins/heyGovMixin";

export default {
    name: "EventLocation",
    components: {},
    data: () => ({
        eventEdit: null,
        eventLocation: null,
        juridiction: null,
        center: null,
        zoom: null,
        place: null,
        options: {
            componentRestrictions: { country: "us" },
            fields: ["address_components", "geometry", "icon", "name", "formatted_address", "place_id"],
            mapTypeControl: false,
        },
    }),
    mounted() {
        window.checkAndAttachMapScript = function (callback) {
            let scriptId = "map-api-script";
            let mapAlreadyAttached = !!document.getElementById(scriptId);

            if (mapAlreadyAttached) {
                if (window.google) callback();
            } else {
                window.mapApiInitialized = callback;

                let script = document.createElement("script");
                script.id = scriptId;
                script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}&libraries=places,geometry&callback=mapApiInitialized`;
                document.body.appendChild(script);
            }

            return mapAlreadyAttached;
        };
        this.loadEvent();

        this.loadJurisdiction();
        setTimeout(() => {
            window.checkAndAttachMapScript(this.initLocationMap);
        }, 1000);
    },

    computed: {
        playModal() {
            return this.$route.meta.play_modal;
        },
    },
    methods: {
        async saveLocation() {
            let uuid;
            if (this.eventEdit.type === "single" || this.eventEdit.type === "recurrent") {
                uuid = this.eventEdit?.uuid ?? this.eventEdit?.id;
            } else if (this.eventEdit.type == "recurrence" && this.eventEdit.source_event) {
                uuid = this.eventEdit.source_event;
            }
            let res = await this.axios.put(`${this.apiRoute}/${this.domain}/events/${uuid}?apiKey=${this.apiKey}`, { location: this.eventLocation });
            if (res && res.status == 200) {
                this.backToEvents();
            }
        },
        async loadEvent() {
            const uuid = this.$route.params.uuid;
            let res = await this.axios.get(`${this.apiRoute}/${this.domain}/events/${uuid}`);
            console.log(res);
            if (res && res.status == 200) {
                this.eventEdit = res.data;
            }
        },
        initLocationMap() {
            const self = this;
            const map = new window.google.maps.Map(document.getElementById("map"), { zoom: this.zoom || 10, center: this.center || { lat: 44.58, lng: -103.46 } });

            let prevMarker;
            if (self.eventEdit && self.eventEdit.location) {
                prevMarker = new window.google.maps.Marker({
                    position: { lat: self.eventEdit.location.lat, lng: self.eventEdit.location.lng },
                    map: map,
                });
            }

            const autocomplete = new window.google.maps.places.Autocomplete(this.$refs.search, this.options);
            window.google.maps.event.addListener(autocomplete, "place_changed", function () {
                let place = autocomplete.getPlace();
                const marker = new window.google.maps.Marker({
                    position: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() } || this.center,
                    map: map,
                });
                if (prevMarker) {
                    prevMarker.setMap(null);
                }

                const infowindow = new window.google.maps.InfoWindow({
                    content: `<p>${place.formatted_address}</p>`,
                });
                window.google.maps.event.addListener(marker, "click", () => {
                    infowindow.open(map, marker);
                });
                self.parseLocation(place);
            });
        },
        async loadJurisdiction() {
            let res = await this.axios.get(`${this.apiRoute}/${this.domain}`);
            if (res && res.status == 200) {
                this.juridiction = res.data;
                this.center = { lat: this.juridiction.location.lat, lng: this.juridiction.location.lng };
                this.zoom = parseInt(this.juridiction.location.zoom);
            }
        },
        parseLocation(place) {
            let street = "";
            let route = "";

            place.address_components.forEach((part) => {
                if (part.types.includes("street_number")) {
                    street = part.long_name;
                } else if (part.types.includes("route")) {
                    route = part.long_name;
                } else if (part.types.includes("locality")) {
                    this.eventLocation = {
                        ...this.eventLocation,
                        city: part.long_name,
                    };
                } else if (part.types.includes("administrative_area_level_1")) {
                    this.eventLocation = {
                        ...this.eventLocation,
                        state: part.short_name,
                    };
                } else if (part.types.includes("postal_code")) {
                    this.eventLocation = {
                        ...this.eventLocation,
                        postal_code: part.long_name,
                    };
                } else if (part.types.includes("country")) {
                    this.eventLocation = {
                        ...this.eventLocation,
                        country: part.short_name,
                    };
                }
            });
            this.eventLocation = {
                ...this.eventLocation,
                line1: `${street} ${route}`.trim(),
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                address: place.formatted_address,
                place_id: place.place_id,
                icon: place.icon,
            };

            return this.eventLocation;
        },
        backToEvents() {
            this.$router.back();
        },
    },
    mixins: [heyGovMixin],
};
</script>
<style scoped>
.card-rounded-3 {
    border-radius: 15px !important;
}
</style>
<style>
body:has(.tw-event-location-container) {
    #tw-header {
        opacity: 50%;
    }
}
</style>
